.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition:    @transition;
  -ms-transition:     @transition;
  -o-transition:      @transition;
}
.transform(@string){
  -webkit-transform: @string;
  -moz-transform: 	 @string;
  -ms-transform: 		 @string;
  -o-transform: 		 @string;
}
.scale (@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: 	 scale(@factor);
  -ms-transform: 		 scale(@factor);
  -o-transform: 		 scale(@factor);
}
.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: 	 rotate(@deg);
  -ms-transform: 		 rotate(@deg);
  -o-transform: 		 rotate(@deg);
}
.shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow:    @string;
  box-shadow:         @string;
}


/*
Hover effect*/




