.btn {
  text-transform: lowercase;

  display: block;
  background: @mainGray;
  text-decoration: none;

  &.round-outline {
    padding: 20px 25px;
    border-radius: 40px;
    border: 1px solid @mainColor;
    font-weight: 500;
    font-size: 1.4em;
    .transition(all .2s ease-in-out);
    &:hover {
      .transform(scale3d(1.05, 1.05, 1.05));
      color: @mainColor;

    }
    &.transparent{
      background: none;
    }

  }
}

a {
  .transition(all .2s ease-in-out);
}

.divided-cta-btn {
  margin-top: 40px;
  text-align: center;
  align-content: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  font-size: 1.9em;
  box-sizing: border-box;
  border-radius: 30px;
  overflow: hidden;
  .btn-left, .btn-right {
    font-weight: 400;
    width: 50%;
    float: left;
  }

  .btn-left {
    color: @grayDark;
    padding: 20px;
    background: -moz-linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
    background: -webkit-linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
    background: -ms-linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
    background: linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
    i{.transition(all ease-in-out .2s)}
    &:hover{
      text-decoration: underline;
    }

  }
  .btn-right {
    &:hover{
      text-decoration: underline;
    }
    background: @grayDark;
    padding: 20px;
    color: @mainColor;

  }
  .btn-mid {
    font-size: 0.7em;
    text-transform: uppercase;
    line-height: 1.7em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: @mainGray;
    color: @mainColor;
  }


}

.portfolio-home-link {
  &:nth-child(even) {
    .portfolio-btn {
      background: @grayDarkest;
    }
  }
  &:nth-child(odd) {
    .portfolio-btn {
      background: @grayAlt;
    }
  }
  &:nth-child(even), &:nth-child(odd) {

  }
  &:hover {
    .portfolio-btn {
      color: @mainGray;
      background: -moz-linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
      background: -webkit-linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
      background: -ms-linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
      background: linear-gradient(45deg, rgb(255, 138, 0) 0%, rgb(255, 84, 0) 100%);
    }
  }

}

.portfolio-btn {
  .transition(all .2s ease-in-out);
  padding: 20px 10px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4em;

}