body {
  border: 15px solid #fff;

  min-height: 100vh;

  box-sizing: border-box;
  background: @bodyBackground;
  min-width: 326px;
  position: relative;

  &.overlay {
  }
}

#top, #bottom, #left, #right {
  background: #fff;
  position: fixed;
  z-index: 999;
}
#left, #right {
  height: 200%;
  top: 0; bottom: 0;
  width: 15px;
}
#left { left: 0; }
#right { right: 0; }
#top, #bottom {
  left: 0; right: 0;
  height: 15px;
}
#top { top: 0; }
#bottom { bottom: 0; }


.divider{

  height:2px;
  background: @grayLight;
  margin: 40px 0;
}


.left-floated {
  float: left;

}
.align-marger {
  margin-bottom: 50px;
}

.underline {
  text-decoration: underline;
}

a:hover {
  color: @mainColor;
}

.grid-container *&.nomargin, *.nomargin {
  grid-gap: 0;
  margin: 0;
  grid-column-gap: 0 !important;
}


.right-floated {
  float: right;
}

.wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(~'100% - 30px');
}

.div {
  display: block;

  color: inherit;
  text-decoration: none;
  &:hover {

    text-decoration: none;
  }
}
.cleared {
  clear: both;
}


.text-spacer {
  height: 8px;
}

.center-aligned {
  text-align: center;
}
.sheet-padder{
  padding: @mainSpacing 0;
}