.portfolio-header{

  height: 500px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .header-text{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#313131+0,313131+100&0.38+0,0+0,0.66+67,1+93,1+100 */
    background: -moz-linear-gradient(top, rgba(49,49,49,0) 0%, rgba(49,49,49,0.66) 67%, rgba(49,49,49,1) 93%, rgba(49,49,49,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(49,49,49,0) 0%,rgba(49,49,49,0.66) 67%,rgba(49,49,49,1) 93%,rgba(49,49,49,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(49,49,49,0) 0%,rgba(49,49,49,0.66) 67%,rgba(49,49,49,1) 93%,rgba(49,49,49,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00313131', endColorstr='#313131',GradientType=0 ); /* IE6-9 */
    position: absolute;
    bottom:-1px;
    right:0;
    left:0;
    margin:auto;
    padding-bottom: 40px;

    h2{
      text-transform: none;
      color: @mainColor;
    }
    p{
      font-size: 1.2em;
      max-width: 820px;
      margin:auto;
    }
  }
}
.portfolio-items{
  h1{
    #font > .headerMargins(2.3em);
  }
  h2{
    #font > .headerMargins(1.8em);
    text-transform: none;
  }
  button.portfolio{
    .btn();

  }
}
.portfolio-item{
  padding-bottom: 90%;
  max-height: 700px;

  .title{
    float:left;
    max-width: 477px;
  }
}
.portfolio-item-title{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#313131+0,313131+100&1+0,1+58,1+58,0.66+84,0.39+100,0.38+100 */
  //background: -moz-linear-gradient(top, rgba(49,49,49,0.97) 0%, rgba(49,49,49,0.7) 58%, rgba(49,49,49,0.5) 70%, rgba(49,49,49,0.1) 100%); /* FF3.6-15 */
  //background: -webkit-linear-gradient(top, rgba(49,49,49,0.97) 0%,rgba(49,49,49,0.7) 58%,rgba(49,49,49,0.5) 70%,rgba(49,49,49,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
  //background: linear-gradient(to bottom, rgba(49,49,49,0.97) 0%,rgba(49,49,49,0.7) 58%,rgba(49,49,49,0.5) 70%,rgba(49,49,49,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#313131', endColorstr='#61313131',GradientType=0 ); /* IE6-9 */
  border:none;
  background: linear-gradient(to bottom, rgba(35, 35, 35, 1) 0%, rgba(35, 35, 35, 0.7) 50%, rgba(35, 35, 35, 0) 100%);
  span{
    color: @mainColor;
  }
  &:hover{
    span{
      .transform(scale(1.05));
    }
  }
}
.portfolio-gallery{
  img{
    width: 100%;
  }
}
.sub-portfolio{
  a{ color: inherit}
  margin: 40px 0;
  .subGalleryCard{
    position: relative;
    background: @grayDark;
    margin-bottom: 30px;
    img{
    }

    &:after{
      z-index: 0;
      display: block;
      content: '';
      width: 100%;
      height: 20px;
      bottom:-20px;
      left:0;
      position: absolute;
      background-image: -webkit-linear-gradient( 0deg, rgb(50,50,50) 0%, rgb(39,39,39) 100%);
      background-image: linear-gradient( 0deg, rgb(50,50,50) 0%, rgb(39,39,39) 100%);
    }

    article{

      padding: 30px 30px 70px;
      text-align: center;
      p{
       margin: 10px 0 0 0;
      }
      h2{
        #font > .headerMargins(1.8em);
        color: @mainColor
      }
    }
    .btn{
      .transition(all .2s ease-in-out);
      padding: 10px 0;
      background: none;
      z-index: 1;

      color: @mainColor;
      font-size: 1.4em;
      border-top: 1px solid @mainColor;
      border-left: 1px solid @mainColor;
      border-right: 1px solid @mainColor;
      border-radius: 35px 35px 0 0;
      max-width: 150px;
      margin: auto;
      box-sizing: border-box;


      position: absolute;
      min-width: 200px;
      bottom: 0;
      left:0;
      right:0;
      &:after{
        position: absolute;
        content: '';
        display: block;
        bottom: -1px;
        left:-2px;

        width: calc(~'100% + 4px');
        height: 50px;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#272727+0,272727+100&0+70,1+100 */
        background: -moz-linear-gradient(top, rgba(39,39,39,0) 0%, rgba(39,39,39,0) 70%, rgba(39,39,39,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(39,39,39,0) 0%,rgba(39,39,39,0) 70%,rgba(39,39,39,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(39,39,39,0) 0%,rgba(39,39,39,0) 70%,rgba(39,39,39,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00272727', endColorstr='#272727',GradientType=0 ); /* IE6-9 */

      }
    }
    .img-container{
      position: relative;
      overflow: hidden;

      &:before{
        .transition(all .2s ease-in-out);
        content: '';
        z-index: 2;
        position: absolute;
        background-image: -moz-linear-gradient( 0deg, rgb(39,39,39) 0%, rgba(39, 39, 39, 0) 100%);
        background-image: -webkit-linear-gradient(0deg, rgb(39,39,39) 0%, rgba(39, 39, 39, 0) 100%);
        background-image: -ms-linear-gradient( 0deg, rgb(39,39,39) 0%, rgba(39, 39, 39, 0) 100%);
        background-image: linear-gradient(0deg, rgb(39,39,39) 0%, rgba(39, 39, 39, 0) 100%);
        background-image: linear-gradient(0deg, rgb(39,39,39) 0%, rgba(39, 39, 39, 0) 100%);
        display: block;
        content: '';
        width: 100%;
        height: 82px;
        bottom: -2px;
      }
      &.referentie{

        &:before{
          content: '';
          z-index: 2;
          position: absolute;
          background-image: -moz-linear-gradient( 360deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
          background-image: -webkit-linear-gradient( 360deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
          background-image: -ms-linear-gradient( 360deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
          background-image: linear-gradient(360deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
          display: block;
          content: '';
          width: 100%;
          height: 82px;
          bottom: 0;
        }
      }
      img{
        .transition(all .4s ease-out);
        display: block;
        position: relative;
        width: 100%;

      }
    }
    &:hover{
      img{
        .transform(scale(1.02));
      }
      p{
        color: #fff;
      }

      .img-container:before{
        opacity: 0.8;

      }
      .btn{
        .transform(translateY(-3px));

      }

    }


  }
}

/*
Portfolio ITEM overview
*/
.portfolio-item-img{
  height: 700px;
  width: 100%;
}
.grid-item{
  margin-bottom: 30px;

  &:hover{

    img {
      .transform(scale(1.015));
      -webkit-box-shadow: 0px 1px 9px 5px #232323;
      box-shadow: 0px 1px 9px 5px #232323;
    }
  }
  img{
    .transition(all .2s ease-in-out);
    display: block;
    width: 100%;
  }
}
.masonry-grid{
  margin-top: 50px;
  figure{
    margin:0;
  }
}

.pswp__tags{
  position: fixed;
  top:0;
  max-width: 420px;
  margin: 0 auto;
  left:0;
  right:0;
  text-align: center;
}
.pswp__caption__center{
  text-align: center;
}

.tag-label{
  padding: 20px;
  background: #000;
  border-radius: 3px;
}



.edit-item{
  position: absolute;
  top:-30px;
  margin:auto;
  z-index: 9999;
}
.portfolio-navigation{
  position: absolute;
  width: 100%;
  bottom:0;
  z-index: 0;
  i{
    font-size: 1.2em;
    .transition(all .2s ease-in-out);
  }
  a{

    &:nth-child(2){
      &:hover{ i{animation: .4s ease-in-out flyaway-u}}
      margin: auto;
      display: block;
      left: 0;
      right: 0;
      max-width: 65px;
    }
    &.disabled{
      pointer-events: none;
      cursor: default;
      text-decoration: none;
      border: 1px solid @grayDark;
      i{
        color: @grayDark;
      }
    }

    border-radius: 33px 33px 0 0;
    display: inline-block;

    bottom:-40px;
    position: absolute;
    padding: 20px;
    border: 1px solid @mainColor;
    &:after{
      content: '';
      position: absolute;
      width: calc(~'100% + 2px');

      @fromColor : @mainGray;
      @toColor : rgba(49, 49, 49, 0);
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
      background: -moz-linear-gradient(bottom, @fromColor 0%, @fromColor 4%, @toColor 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(bottom, @fromColor 0%, @fromColor 4%,@toColor 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to top, @fromColor 0%, @fromColor 4%,@toColor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

      height: 20px;
      bottom:0;
      left:-1px;
      bottom:-1px;
      right:0;
      margin: auto;
      z-index: 999;
    }
  }




  .portfolio-next{

    right:0;
    &:not(.disabled){ &:hover{ i{animation: .2s ease-in-out flyaway-r} } }
  }
  .portfolio-previous{
    left:0;

      &:not(.disabled){ &:hover{ i{animation: .2s ease-in-out flyaway-l} } }

  }
}
figure a{
  display: block;
}

img.lazy{


  //-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
  //filter: blur(5px);
}
img.load{
  animation: bloob .2s ease-in-out;
}
.portfolio-navigation{
  &:after{
    animation: bloob .2s ease-in-out;
    width: 100%;
    height: 40px;
    background: #313131;
    position: absolute;
    bottom: -75px;
    left: 0;
    right: 0;
    margin: auto;
    content: '';
  }
}